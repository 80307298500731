import React, { useEffect, useState } from "react";

import { LeadBarStyled } from "./LeadBar.Styles";
import { FlexContainer } from "./../Flex/Flex.Styles";

import { useRecoilValue } from "recoil";
import { leadAtom } from "atoms/lead";
import Search from "./../Search/Search";
import CreateLead from "./../CreateLead/CreateLead";
import searchLead from "api/searchLead";
import Spinner from "components/Spinner/Spinner";
import CallBadge from "./IncomingCallInfo/CallBadge";
import createLead from "api/createLead";
import getServicability from "api/getServiceability";
import getContact from "api/getContact";

interface LeadBarrops {
  setApiError: React.Dispatch<React.SetStateAction<boolean>>;
  setIsServiceability: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveRequestText: React.Dispatch<React.SetStateAction<string>>;
}

const LeadBar = ( props: LeadBarrops): React.JSX.Element => {

  const leadData: any = useRecoilValue(leadAtom);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState<any>();
  const [parentId, setParentId] = useState<number>();
  const [officeData, setOfficeData] = useState<any>({});
  const [parentLeadData, setParentLeadData] = useState<any>();
  
  useEffect(() => {
    if(leadData?.postal_code) {
      getOfficeData(leadData?.postal_code);
    }
  }, [leadData?.postal_code]);
  
  useEffect(() => {
    if(officeData) {
      props.setIsServiceability(officeData.is_serviceable);
    }
  }, [officeData]);
  
  useEffect(() => {
    if(leadData) {
      leadData.event_contact_parent_id === null ? setParentId(leadData.contact_id) : setParentId(leadData.event_contact_parent_id);
    }
  });
  
  useEffect(() => {
    parentId && search();
  }, [parentId]);

  useEffect(() => {
    if (leadData && leadData.event_contact_parent_id !== null && parentId) {
      getLeadData();      
    }
  }, [parentId]);

  async function getLeadData () {
    setLoading(true);
    try {
      if(parentId) {
        const leadResponse = await getContact(parentId);
        setParentLeadData({...leadResponse.data });
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }

  const getOfficeData = async (zip: number) => {
    try {
      if(zip) {
        const res = await getServicability(zip);
        if(res.data) {
          setOfficeData(res.data);
        }
      }
    } catch (error) {
      props.setApiError(true);
      props.setSaveRequestText("Failure to get Office data");
    }
  };

  const search = async () => {
    try {
      setLoading(true);
      const req = await searchLead({
        parent_id: parentId,
      });

      setSearchData(req.data);
      props.setApiError(false);
    } catch (e) {
      props.setSaveRequestText("Failure to get tied accounts");
      props.setApiError(true);
    }

    setLoading(false);
  };

  const selectOpt = searchData?.map((item: { system_id: any; address_line_1: any; city: any; province: any; postal_code: any; }) => ({
    value: item.system_id,
    label: `${item.address_line_1 ? item.address_line_1 + '.' : ""} ${item.city ? item.city + ',' : ""} ${item.province ? item.province : ""} ${item.postal_code ? item.postal_code: "No Address"}`
  }));

  parentLeadData && selectOpt && selectOpt.push({value: parentLeadData.event_id, label: `${parentLeadData.address_line_1 ? parentLeadData.address_line_1 + '.' : ""} ${parentLeadData.city ? parentLeadData.city + ',' : ""} ${parentLeadData.province ? parentLeadData.province : ""} ${parentLeadData.postal_code ? parentLeadData.postal_code: "No Address"}`});
  selectOpt && selectOpt.push({value: "Add", label: "+ Add New Property"});

  const handleSelectChange = (selectedValue: string) => {
    if(selectedValue === "Add") {
      addProperty();
    } else window.location.replace(`/${selectedValue}`);
  };
  let placeHolder = '';
  if(leadData?.event_contact_parent_id === null) {
    placeHolder = `${leadData.address_line_1 ? leadData.address_line_1 + '.' : ""} ${leadData.city ? leadData.city + ',' : ""} ${leadData.province ? leadData.province : ""} ${leadData.postal_code ? leadData.postal_code: "No Address"}`;
  }
  
  const addProperty = async () => {
    try {
      // Strip out masking
      setLoading(true);
      const unmaskedPhoneNum = leadData?.phone_number.replace(/\D/g, "");
      if (unmaskedPhoneNum.length === 10) {
        const resp = await createLead(unmaskedPhoneNum);
        window.location.replace(`/${resp.data.event_id}`);
      }
    } catch (e: any) {
      setLoading(false);
      props.setApiError(true);
      props.setSaveRequestText("Failure to add new property");
    }
  };

  return (
    <LeadBarStyled data-testid="lead-bar">
      {loading && <Spinner />}
      <FlexContainer
        className="lead-modal__header"
        justifyContent="space-between"
      >
        <FlexContainer>
          
          {searchData && 
          <CallBadge
            firstName={leadData?.name_first}
            lastName={leadData?.name_last}
            callNumber={leadData?.phone_number}
            customerId={leadData?.pr_customer_id}
            emailAddress={leadData?.email_address}
            subscriptionId={leadData?.pr_subscription_id}
            options={selectOpt || []}
            value={leadData?.system_id}
            handleSelectChange={handleSelectChange}
            addProperty={addProperty}
            placeHolder={placeHolder}
            officeData={officeData}
          />
          }
        </FlexContainer>
        <FlexContainer gap={10} justifyContent="space-between">
          <CreateLead />
          <Search />
        </FlexContainer>
      </FlexContainer>
    </LeadBarStyled>
  );
};

export default LeadBar;
