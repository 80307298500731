import React from "react";

import { Lead } from "./../../../atoms/lead";
import LeadInfoForm from "./LeadInfoForm";
import { stateOptions, pestsOptions, propertyOptions } from "./../../../constants";

interface LeadMainInfoProps {
  closer?: boolean;
  setData: React.Dispatch<React.SetStateAction<Lead>>;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  data: Lead;
  errors: string[];
  isIniAptCmplted: boolean;
  isServiceability: boolean;
}

const getFormFields = (closer?: boolean) => {
  return [
    {
      // label: "Phone number",
      component: "phoneInput",
      placeholder: "e.g., 555-867-5309",
      additionalClassName: "_p-right",
      name: "phone_number",
      required: true,
    },
    {
      label: "Email address",
      component: "debounceInput",
      placeholder: "e.g., john.doe@example.com",
      name: "email_address",
      additionalClassName: "_p-left",

      // eslint-disable-next-line
      validator: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,      
      required: closer,      
    },
    {
      label: "First name",
      component: "input",
      placeholder: "e.g., John",
      name: "name_first",
      additionalClassName: "_p-right",
      regexp: /^[A-Za-z]+$/,
    },
    {
      label: "Last name",
      component: "input",
      placeholder: "e.g., John",
      additionalClassName: "_p-left",
      name: "name_last",
      regexp: /^[A-Za-z]+$/,
    },
    {
      label: "Street Address",
      component: "input",
      placeholder: "e.g., 456 Wasp Way",
      name: "address_line_1",
      id: "address_line_1",
      additionalClassName: "_large-field _no-padding",
      autoComplete: "on",
    },
    {
      label: "City",
      component: "input",
      placeholder: "e.g., Boulder Bee",
      name: "city",
      id: "city",
      additionalClassName: "_medium-field _p-right",
    },
    {
      label: "State",
      component: "select",
      placeholder: "Washington",
      options: stateOptions,
      name: "province",
      id: "province",
      additionalClassName: "_medium-field _double-padding",
    },
    {
      component: "zipInput",
      placeholder: "e.g., 90210",
      name: "postal_code",
      id: "postal_code",
      regexp: /^[0-9\b]+$/,
      additionalClassName: "_medium-field _p-left",
    },
    {
      label: "Property",
      component: "select",
      placeholder: "Select property",
      options: propertyOptions,
      isMulti: false,
      name: "property_type_id",
      additionalClassName: "_p-right",
    },
    {
      label: "Pests",
      component: "select",
      placeholder: "Select pests of concern",
      options: pestsOptions.sort((a, b) => a.label.localeCompare(b.label)),
      isMulti: true,
      name: "pest_types",
      additionalClassName: "_p-right",
    },
    {
      label: "Door Hanger Code",
      component: "input",
      placeholder: "e.g., 12345",
      name: "qr_code_id",
      id: "qr_code_id",
      additionalClassName: "_p-right",
      regexp: /^[0-9]*([.][0-9]*)?$/,
    },
  ];
};

const LeadMainInfo = (props: LeadMainInfoProps): React.JSX.Element => {
  return (
    <>
      <LeadInfoForm {...props} formFields={getFormFields()} />
    </>
  );
};

export default LeadMainInfo;
